$B1: #000000;
$B2: #262626;
$B3: #333333;
$B4: #666666;
$B4b: #999999;
$B5: #cccccc;
$B6: #f5f5f5;
$B7: #00afd0;
$B8: #00a197;
$B9: #ffffff;
$B10: #aa1c0d;
$B11: #e2001a;
$B12: #ea5c4d;
$B13: #f8f8f8;

$button-light-color: $B7;
$button-light-background: #bfebf3;
$public-background: #f5fcfc;
$disabled-backgroud: #e9ecef;
$user-menu-background: $B7;
$user-submenu-background: #208ba6;
$menu-background: #505566;
$menu-background-active: #434858;

$tennant: $B11;
$primary: $B7;
$success: #35a000;
$danger: $B10;
$light: $button-light-background;

$border-radius: 3px !default;
$border-radius-lg: 3px !default;
$border-radius-sm: 3px !default;

$base-padding: 1rem;
$base-padding-2x: $base-padding*2;
$base-padding-3x: $base-padding*3;
$base-padding-4x: $base-padding*4;
$half-padding: $base-padding/2;
$small-padding: $base-padding/4;

$base-margin: 1rem;
$base-margin-2x: $base-margin*2;
$base-margin-4x: $base-margin*4;
$half-margin: $base-margin/2;
$small-margin: $base-margin/4;

$menu-active-border-width: 5px;
$navbar-item-height: 60px;
$avatar-size: 45px;
$icon-size: 64px;
$menu-width: 300px;
$checkbox-size: 37px;
$radio-size: 27px;
$logo-size: 300px;

$menu-active-border: $menu-active-border-width solid $B7;
$disabled-border: 1px solid #ced4da;
$table-border: 1px solid #ececec;

$box-shadow: 2px 2px 20px transparentize($B5, 0.7);

$mobile-bp: 600px;
$tablet-bp: 960px;
$desktop-bp: 1280px;

$font-roboto: 'Roboto', sans-serif;
$font-source-sans: 'Source Sans Pro', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;


